
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
export default function menu(drawer) {
      var state = false;
  var drawerB = document.querySelector('.drawer-btn');
  var drawermodal = document.querySelector('.gnav');
  var drawerBG = document.querySelector('.gnav-overlay');

  drawerB.addEventListener('click', () => {
    if (state == false) {
      document.documentElement.style.overflow = 'hidden';
      disableBodyScroll(drawermodal);
      drawerB.classList.add('active');
      drawermodal.classList.add('open');
      drawerBG.classList.add('open');
      state = true;
    } else {
      document.documentElement.style.overflow = '';
      enableBodyScroll(drawermodal);
      drawerB.classList.remove('active');
      drawermodal.classList.remove('open');
      drawerBG.classList.remove('open');
      state = false;
    }
  });

  const drawerClosebuttons =document.querySelectorAll('.js-close');
  Array.from(drawerClosebuttons).forEach((btn) => {
    btn.addEventListener('click',() =>{
      enableBodyScroll(drawermodal);
      document.documentElement.style.overflow = '';
    drawerB.classList.remove('active');
    drawermodal.classList.remove('open');
    drawerBG.classList.remove('open');
    state = false;
  });
});
}
