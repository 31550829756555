import {
  gsap
} from "gsap";

export default function menu() {
// var userAgent = window.navigator.userAgent;
//   // if (window.matchMedia('(min-width: 671px)').matches) {
//     if(userAgent.indexOf('msie') != -1 ||
//     userAgent.indexOf('trident') != -1) {
//       return;

//     }else {

      var t2 = gsap.timeline();

      t2.staggerTo('.opening__title', .1, {
        delay: .5,
        y: 0,
        opacity: '1'
      }).staggerTo('#item01', .1, {
          delay: .5,
          y: 0,
          opacity: '1'
        })
        .staggerTo('#item02', .1, {
          delay: .13,
          y: 0,
          opacity: '1'
        })
        .staggerTo('#item03', .1, {
          delay: .13,
          y: 0,
          opacity: '1'
        })
        .staggerTo('#item04', .1, {
          delay: .13,
          y: 0,
          opacity: '1'
        })
        .staggerTo('#item05', .1, {
          delay: .13,
          y: 0,
          opacity: '1'
        })
        .staggerTo('#item06', .1, {
          delay: .13,
          y: 0,
          opacity: '1'
        })
        .staggerTo('#item07', .1, {
          delay: .13,
          y: 0,
          opacity: '1'
        })
        .staggerTo('#item08', .1, {
          delay: .13,
          y: 0,
          opacity: '1'
        })
        .staggerTo('#item09', .1, {
          delay: .13,
          y: 0,
          opacity: '1'
        })
        .staggerTo('#item10', .1, {
          delay: .13,
          y: 0,
          opacity: '1'
        })
        .staggerTo('.jsOpening', .3, {
          delay: .4,
          y: 0,
          opacity: '1'
        })
    // }


}
