export default function menu() {

  if (window.matchMedia('(max-width: 670px)').matches) {

    // 途中からボタンを表示
    $(window).scroll(function () {
      if ($(this).scrollTop() > 400) {
        $('.footer-sp').fadeIn();
        $('.pagetop').fadeIn();
      } else {
        $('.footer-sp').fadeOut();
        $('.pagetop').fadeOut();

      }
    });

  }else {
        // 途中からボタンを表示
        $(window).scroll(function () {
          if ($(this).scrollTop() > 400) {
            $('.pagetop').fadeIn();
          } else {
            $('.pagetop').fadeOut();

          }
        });
  }
}
