import Swiper from 'swiper';

export default function swiper() {

  const slider1 = new Swiper('.works-slider', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.button-next01',
      prevEl: '.button-prev01',
    },
    breakpoints: {
      1400: {
        slidesPerView: 2,
        spaceBetween: 52,
      },
      1200: {
        slidesPerView: 2,
        spaceBetween: 42,
      },
      671: {
        slidesPerView: 2,
        spaceBetween: 22,
      }
    }
  });

  const slider2 = new Swiper('.staff-slider', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.button-next02',
      prevEl: '.button-prev02',
    },
    breakpoints: {
      1400: {
        slidesPerView: 4,
        spaceBetween: 52,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 42,
      },
      961: {
        slidesPerView: 4,
        spaceBetween: 32,
      },
      690: {
        slidesPerView: 3,
        spaceBetween: 22,
      },
      571: {
        slidesPerView: 2,
        spaceBetween: 18,
      }
    }
  });

  const slider3 = new Swiper('.book-slider', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.button-next03',
      prevEl: '.button-prev03',
    },
    breakpoints: {
      1400: {
        slidesPerView: 2,
        spaceBetween: 52,
      },
      1200: {
        slidesPerView: 2,
        spaceBetween: 42,
      },
      671: {
        slidesPerView: 2,
        spaceBetween: 38,
      }
    }
  });

}
