
/* Modules
==================== */

// ドロワー
import drawer from "./modules/drawer";
drawer();

// スライダー
import swiper from "./modules/swiper";
swiper();

// スムーススクロール
import smoothScroll from "./modules/smoothScroll";
smoothScroll();

// opening
import opening from "./modules/opening";
opening();

// モーダル
import modal from "./modules/modal";
modal();

// SPボタン
import spBtn from "./modules/spBtn";
spBtn();


import word from "./modules/word";
word();


/* Script
==================== */
